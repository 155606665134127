<template>
  <div id="app">
    <div class="loading-wrapper" v-if="loading">
      <updox-loading color="#003462" size="80px" />
    </div>
    <div v-else>
      <app-header @log-out="logout" />
      <router-view />
    </div>
  </div>
</template>

<script>
  import AppHeader from "./components/common/Header";
  import { UpdoxLoading } from "@updox/common-vue";
  import HealthService from "./services/HealthService";
  import MemberService from "@/services/MemberService";
  import * as Sentry from "@sentry/vue";

  export default {
    components: { AppHeader, UpdoxLoading },
    data: () => ({
      loading: true
    }),
    async mounted() {
      try {
        const memberRoute = this.$route?.meta?.memberRoute;
        if (memberRoute) {
          await this.loadMember();
        }
      } catch (e) {
        let { isForwarding } = e;
        if (!isForwarding) {
          Sentry.captureException(
            new Error("Unable to process member login", { cause: e })
          );
          await this.$router.push("/error");
        }
        return;
      }
      HealthService.endpointStatus().then(response =>
        console.log("The spring app is:", response)
      );

      if (this.$store.getters.browserIsInternetExplorer) {
        // It was working, but now it's broke?
        // https://github.com/vuejs/vue-router/issues/1849
        window.addEventListener(
          "hashchange",
          function() {
            var currentPath = window.location.hash.slice(1);
            if (this.$route.path !== currentPath) {
              this.$router.replace(currentPath);
            }
          }.bind(this),
          false
        );
      }
      this.loading = false;
    },
    methods: {
      logout() {
        this.$store.commit("setMember", null);
        this.$store.commit("setProviderId", null);
      },
      async loadMember() {
        const member = await MemberService.getMember();
        this.$store.commit("setMember", member);
        Sentry.setUser({
          memberId: member.id,
          pid: member.pid,
          role: "Participant"
        });
      }
    }
  };
</script>

<style lang="scss">
  @import "/assets/styles/_imports.scss";
  @import "~updox-styles/src/global/style/main.css";

  html,
  body {
    height: 100%;
    background: #f4f6f8;
  }

  #app {
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>
