import * as Sentry from "@sentry/vue";
import Vue from "vue";

const mode = process.env.NODE_ENV;
const isProductionBuild = mode === "production";
const isKonica = window.location.hostname.includes("myupdoxca");

// A chance to send local logs to Sentry, regardless of environment
const localSentry = process.env.VUE_APP_LOCAL_SENTRY;
const useLocalSentry = localSentry === "true";

const sentryEnvironment =
  mode === "production"
    ? // assume production unless the hostname matches one of our lower envs or associated vanity domains
      window.location.hostname.match(
        /updoxtest|updoxqa|qa\.sharecaretelehealth|updoxperf|localhost/
      )
      ? "testing"
      : "production"
    : "development";

const initializeSentry = () => {
  const useSentry = (isProductionBuild || useLocalSentry) && !isKonica;
  if (useSentry) {
    console.debug(`Initializing Sentry for env [${sentryEnvironment}].`);
    Sentry.init({
      Vue,
      dsn:
        "https://fc04e7366ea61071ed0a8542cb7b1e4a@o4506220010274816.ingest.us.sentry.io/4508779730960385",
      attachStacktrace: false,
      environment: sentryEnvironment
    });
  } else {
    console.debug(
      `Not initializing a logging service. env [${sentryEnvironment}] is not US production and local Sentry is [${localSentry}].`
    );
  }
};

export { initializeSentry };
