<template>
  <div>
    <div class="form-utility" v-if="!error && !formsModuleDisabled">
      <h1 class="identity">
        {{ !this.portalForms ? " " : this.portalForms.practiceName }}
      </h1>
      <p class="description">
        Access and send practice forms here
      </p>
      <tool-bar @filter-value="filterValue" />
    </div>
    <div class="form-list">
      <div v-if="error">
        <updox-error />
      </div>
      <div v-else-if="formsModuleDisabled">
        <forms-app-disabled :info-details="infoDetails" />
      </div>
      <div v-else-if="loading" class="loading-wrapper">
        <updox-loading color="#003462" size="80px" />
      </div>
      <div v-else-if="simpleFormsView" class="forms">
        <alert-banner :alert-details="alertDetails" />
        <div
          v-if="portalForms && portalForms.forms && filteredItems.length"
          class="container"
        >
          <ul class="list">
            <li
              v-for="(form, index) in filteredItems"
              :key="form.id"
              :id="'list-' + index"
              class="list-item"
            >
              <a
                @click="expandCollapse(form.id)"
                :id="form.id + '-form-detail'"
                class="mobile-container"
              >
                <span class="mobile-title">{{ form.title }}</span>
                <div
                  :id="form.id + '-form-actions'"
                  style="display: none"
                  class="padding"
                >
                  <open-form-in-current-window :form="form" />
                  <copy-to-clip-board-link :form="form" />
                  <print-form
                    :form="form"
                    :is-embedded="false"
                    @print-fail="printFailure"
                  />
                </div>
              </a>
              <a class="disabled-link" href="">{{ form.title }}</a>
              <open-form-in-current-window
                :form="form"
                class="desktop-container"
              />
              <copy-to-clip-board-link :form="form" class="desktop-container" />
              <print-form
                :form="form"
                class="desktop-container"
                :is-embedded="false"
                @print-fail="printFailure"
              />
            </li>
          </ul>
        </div>
        <div v-else class="container no-forms-found">
          No forms found
        </div>
      </div>
      <div v-else>
        <div class="header">
          <h1 class="page-header">Forms Library</h1>
          <p class="page-header-description">
            Access and send practice forms here
          </p>
        </div>
        <div class="flex-row">
          <div class="page-content page-margins">
            <div
              v-if="
                portalForms && portalForms.forms && portalForms.forms.length
              "
              class="container"
            >
              <ul class="list">
                <li class="list-title">
                  Available Forms
                </li>
                <li
                  v-for="(form, index) in portalForms.forms"
                  :key="form.id"
                  :id="'list-' + index"
                  class="list-item"
                >
                  <a
                    class="truncate-text"
                    @click="loadForm(`${form.id}`, index)"
                    :id="form.id"
                    :title="form.title"
                  >
                    {{ form.title }}
                  </a>
                </li>
              </ul>
              <single-form
                v-if="loadedForm && loadedForm.fields.length > 0"
                :forms-data="loadedForm"
              />
            </div>
            <div v-else class="container no-forms-found">
              No forms found
            </div>
          </div>
        </div>
      </div>
      <updox-footer class="padding" />
    </div>
  </div>
</template>

<script>
  import FormsService from "../services/FormsService";
  import { UpdoxLoading } from "@updox/common-vue";
  import SingleForm from "./SingleForm";
  import UpdoxError from "../components/common/UpdoxError";
  import CopyToClipBoardLink from "../components/helpers/CopyToClipBoardLink";
  import OpenFormInCurrentWindow from "../components/helpers/OpenFormInCurrentWindow";
  import FormsAppDisabled from "../components/common/FormsAppDisabled";
  import ToolBar from "../components/helpers/ToolBar";
  import UpdoxFooter from "../components/common/UpdoxFooter";
  import PrintForm from "../components/helpers/PrintForm";
  import AlertBanner from "../components/helpers/AlertBanner";
  import * as Sentry from "@sentry/vue";

  export default {
    name: "FormList",
    data: () => ({
      loading: true,
      error: false,
      formsModuleDisabled: false,
      infoDetails: null,
      portalForms: null,
      formsContent: [],
      loadedForm: null,
      simpleFormsView: true,
      filterText: "",
      formToPrint: null,
      alertDetails: {
        show: false,
        showType: "hidden",
        success: false,
        isFormError: false,
        defaultErrorMessage: ""
      }
    }),
    async mounted() {
      const pid = this.$route.params.pid;
      await FormsService.getForms(pid)
        .then(response => {
          this.portalForms = response;
          this.$store.commit("setProviderId", pid);
        })
        .catch(e => {
          let { formsModuleDisabled } = e;
          this.loading = false;

          if (formsModuleDisabled) {
            let responseJson = e?.response?.data;
            this.formsModuleDisabled = true;
            this.infoDetails = {
              ...responseJson
            };
          } else {
            this.error = true;
            console.log(`Error loading forms,`, e);
            Sentry.captureException(
              new Error(`Error loading forms`, { cause: e })
            );
          }
        });

      if (!this.simpleFormsView && this.portalForms?.forms?.length) {
        FormsService.getFormById(this.portalForms.forms[0].id).then(
          response => {
            if (this.loadedForm === null) {
              this.loadedForm = response;
            }

            this.setSelectedForm(0, false);

            this.formsContent.push(response);
          }
        );

        this.$store.commit("setFormLoaded", true);
      }
      this.loading = false;
    },
    methods: {
      loadForm: async function(formId, index) {
        let currForm = this.formsContent.find(item => item.id + "" === formId);

        if (currForm === undefined) {
          await FormsService.getFormById(formId).then(response => {
            this.formsContent.push(response);
            this.loadedForm = response;
          });
        } else {
          this.loadedForm = currForm;
        }

        if (index) {
          this.setSelectedForm(index, true);
        }
      },
      setSelectedForm: function(index, prevFormSelected) {
        if (prevFormSelected) {
          document.getElementsByClassName("selected")[0].className =
            "list-item";
        }
        document.getElementsByClassName("list-item")[index].className =
          "selected";
      },
      // @TODO for later: refactor in favor of v-if and v-class
      expandCollapse: function(formId) {
        let formDetails = document.getElementById(`${formId}-form-detail`);
        let formActions = document.getElementById(`${formId}-form-actions`);

        if (formActions.style.display === "none") {
          formActions.style.display = "block";
          formDetails.style.backgroundColor = "rgba(0, 127, 173, 0.15)";
          formDetails.style.border = "1px solid rgba(0, 130, 173, 0.15)";
        } else {
          formActions.style.display = "none";
          formDetails.removeAttribute("style");
        }
      },
      filterValue: function(val) {
        this.filterText = val;
      },
      printFailure: function() {
        this.alertDetails = {
          show: true,
          showType: "danger",
          success: false,
          isFormError: false,
          defaultErrorMessage: "Unable to print form"
        };
      }
    },
    components: {
      AlertBanner,
      PrintForm,
      UpdoxFooter,
      ToolBar,
      OpenFormInCurrentWindow,
      CopyToClipBoardLink,
      FormsAppDisabled,
      UpdoxError,
      SingleForm,
      UpdoxLoading
    },
    computed: {
      filteredItems() {
        return this.portalForms?.forms?.filter(
          item =>
            item.title.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .form-list {
    background: #f4f6f8;
    margin-top: 220px; // offset of fixed Header and form-utility css
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .form-utility {
    background: #f4f6f8 !important;
    margin-top: 60px;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  .page-margins {
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .padding {
    padding-top: 10px;
  }

  .identity {
    font-size: 24px;
    padding-left: 30px;
    margin-top: 20px;
  }

  .description {
    font-size: 13px;
    margin-bottom: 20px;
    padding-left: 30px;
  }

  .container {
    background-color: #fff;
    display: flex;
    margin-left: 30px;
    margin-right: 30px;

    /* Stacks the list on top of selected form */
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .desktop-container {
    @media (max-width: 768px) {
      display: none !important;
    }
  }

  .mobile-container {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      flex-grow: 1;
    }
  }

  .mobile-title {
    color: #222;
    font-weight: 600;
  }

  div.no-forms-found {
    font-style: italic;
    padding-left: 15px;
    background-color: #f4f6f8;
  }

  .list {
    flex-grow: 1;
    overflow: auto;
    text-align: center;

    .list-title {
      padding: 20px;
      font-weight: 700;
    }

    li {
      align-items: center;
      border-bottom: 1px solid rgba(0, 130, 173, 0.15);
      display: flex;
      color: #000;
      font-size: 15px;

      &.selected {
        a {
          background-color: rgba(0, 127, 173, 0.15);

          &:hover {
            background-color: rgba(0, 127, 173, 0.15) !important;
          }
        }
      }

      a {
        background-color: #fff;
        color: #003462;
        cursor: pointer;
        padding: 20px 30px;
        transition-duration: 0.2s;
        flex-grow: 1;

        @media (max-width: 768px) {
          padding: 20px;
        }

        &:hover {
          background-color: #f3f3f3;
          text-decoration: none;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: center;
        }

        &.truncate-text,
        &.disabled-link {
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.disabled-link {
          pointer-events: none;
          color: #222;
          font-weight: 600;
          width: 250px; // must be a fixed width so the buttons don't look all jumbled together

          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
</style>
