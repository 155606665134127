<template>
  <div id="router">
    <div v-if="loading" class="loading-wrapper">
      <updox-loading color="#003462" size="80px" />
    </div>
    <div v-else>
      <h1 class="not-needed">Just here for routing</h1>
    </div>
  </div>
</template>

<script>
  import { UpdoxLoading } from "@updox/common-vue";

  export default {
    name: "LoginPage",
    components: { UpdoxLoading },
    data: () => ({
      loading: true
    }),
    mounted() {
      this.loading = false;
      const pid = this.$store.getters.member.pid;
      this.$router.push(`/forms/${pid}`);
    }
  };
</script>

<style scoped>
  .not-needed {
    visibility: hidden;
  }
  .loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 10%;
  }
</style>
