import axios from "axios";
import config from "@/logging/config";
import * as Sentry from "@sentry/vue";

const httpCustomRedirectHeader = "x-message-redirect";
const formsHttpClient = axios.create({
  baseURL: "/api",
  headers: {
    "Correlation-Id": config._sessionId
  }
});

const handleErrorResponse = error => {
  let {
    response: { status: errorCode }
  } = error;
  if (401 === errorCode) {
    let {
      response: {
        headers: { [httpCustomRedirectHeader]: redirect }
      }
    } = error;
    if (redirect) {
      window.location.href = redirect;
      return Promise.reject({ ...error, isForwarding: true });
    } else {
      let msg =
        "User is unauthenticated but no redirect to OAuth Login was found in the response!";
      Sentry.captureException(new Error(msg, { cause: error }));
      console.error(msg);
    }
  } else if (402 === errorCode) {
    // 402 - Payment Required
    return Promise.reject({ ...error, formsModuleDisabled: true });
  } else if (410 === errorCode) {
    // 410 - Gone
    return Promise.reject({ ...error, formAppearsDeleted: true });
  }
  return Promise.reject(error);
};

formsHttpClient.interceptors.response.use(
  response => {
    //store.dispatch(
    // TODO: SessionExpiration component and all that jazz?
    // ActionType.general.SET_SESSION_EXPIRES,
    // moment().add(15, "m")
    //);
    return response;
  },
  error => {
    //  https://github.com/axios/axios#handling-errors
    if (error.response) {
      return handleErrorResponse(error);
    } else if (error.request) {
      let msg = "The request was made, but no response was received";
      Sentry.captureException(new Error(msg, { cause: error }));
      console.error(msg);
      return Promise.reject(error);
    } else {
      let msg =
        "Something happened in setting up the request that triggered an Error";
      Sentry.captureException(new Error(msg, { cause: error }));
      console.error(msg);
      return Promise.reject(error);
    }
  }
);

export { formsHttpClient };
