import Vue from "vue";
import VueRouter from "vue-router";
import FormList from "../views/FormList.vue";
import UpdoxError from "../components/common/UpdoxError";
import LoginPage from "../views/LoginPage";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/forms/:pid",
    name: "FormList",
    component: FormList,
    meta: { memberRoute: true }
  },
  {
    path: "/form/:formId",
    name: "SingleForm",
    // lazy load: won't load until the form is clicked
    component: () =>
      import(/* webPackChunkName: "SingleForm" */ "../views/SingleForm.vue")
  },
  {
    path: "/msg/:msgId",
    name: "EventNotify Message",
    // lazy load until Message is received
    component: () =>
      import(
        /* webPackChunkName: "EventNotifyMessage" */ "../views/EventNotifyMessage.vue"
      )
  },
  {
    path: "/appt/:apptId/:accessCode",
    name: "Appointment Message",
    // lazy load until Message is received
    component: () =>
      import(
        /* webPackChunkName: "ApptReminderMessage" */ "../views/ApptReminderMessage.vue"
      )
  },
  {
    path: "/admin",
    name: "Admin",
    // lazy load: won't load until the admin menu is clicked
    component: () =>
      import(/* webPackChunkName: "FormAdmin" */ "../views/FormAdmin.vue"),
    meta: {
      requiresAuth: true,
      isAdmin: true,
      memberRoute: true
    }
  },
  {
    path: "/error",
    name: "UpdoxError",
    component: UpdoxError
  },
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
    meta: { memberRoute: true }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // TODO this routes using mocked data, what does real state look like?
    if (store.getters.memberIsNotValid) {
      next({
        name: "LoginPage"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
