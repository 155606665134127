import { render, staticRenderFns } from "./FormList.vue?vue&type=template&id=a0497b16&scoped=true&"
import script from "./FormList.vue?vue&type=script&lang=js&"
export * from "./FormList.vue?vue&type=script&lang=js&"
import style0 from "./FormList.vue?vue&type=style&index=0&id=a0497b16&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0497b16",
  null
  
)

export default component.exports