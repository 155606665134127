import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as DOMPurify from "dompurify";
import * as Sentry from "@sentry/vue";
import { initializeSentry } from "@/logging/sentry";

// Do you want mocked client-side data? Uncomment this and run free!
//import { makeServer } from "./server";
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

DOMPurify.addHook("uponSanitizeAttribute", function(node, data) {
  if (
    node.nodeName === "A" &&
    data.attrName === "target" &&
    data.attrValue === "_blank"
  ) {
    var relValue = node.attributes["rel"].value;
    if (
      relValue === "noopener noreferrer" ||
      relValue === "noreferrer noopener"
    ) {
      data.forceKeepAttr = true;
      return (data.attrValue = "_blank");
    } else {
      return (data.attrValue = "");
    }
  }
});

Vue.config.productionTip = false;
initializeSentry();

Vue.config.errorHandler = function(err, vm, info) {
  Sentry.withScope(scope => {
    scope.setContext("info", { msg: info });
    scope.captureException(err);
  });
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
