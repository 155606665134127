import { formsHttpClient } from "../api";
import * as Sentry from "@sentry/vue";

const endpointStatus = async () => {
  //await api.get(`some-url/${pid}`);
  // Fake "loading time"
  let status = "";
  await formsHttpClient
    .get("/actuator/health")
    .then(response => {
      status = response.data.status;
    })
    .catch(err => {
      Sentry.captureException(
        new Error("Unable to communicate with spring app.", { cause: err })
      );
      status = "DOWN [" + err.toString() + "]";
    });

  return status;
};

export default {
  endpointStatus
};
